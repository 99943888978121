import React, { useState, useContext, useEffect} from "react"

import "./style.scss"
import HubbleOrderContext from "../../../context/hubbleOrderContext";
import WebpImage from "../../WebpImage"
import ContactsCartProductList from "../ContactsCartProductList";
import { bestSellers, multifocal, torics } from "./productsList";

const ContactsCartProducts = ({setBrand}) => {
  const { order} = useContext(
    HubbleOrderContext
  )


  return (
    <div className="contacts-cart-products grid-section" id="accessories-section">
      <WebpImage className="contacts-cart-logo" fileName="contacts-cart-hubble.svg"/>

      <h1 className="title">{"Looking for lenses for astigmatism?"}</h1>

      <p className="description">Our partners at ContactsCart offer a large selection of top contact lens brands. And, like us, they make ordering prescription contacts easy, fast, and customizable. You know, the way it should be.</p>

      <div className="product-list-section">
        <ContactsCartProductList title="Featured Bestsellers" products={bestSellers}/>
        <ContactsCartProductList title={"Toric Lenses \nFor Astigmatism"} products={torics}/>
        <ContactsCartProductList title="Multifocal Lenses" products={multifocal}/>
      </div>
    </div>
  )
}

export default ContactsCartProducts
