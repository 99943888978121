import React, { useState, useContext, useEffect} from "react"

import "./style.scss"
import HubbleOrderContext from "../../../context/hubbleOrderContext";
import ContactsCartProductItem from "../ContactsCartProductItem"
import {useWindowWidth} from "../../../services/hooks";

const ContactsCartProductList = ({title, products}) => {
  const windowWidth = useWindowWidth();
  const [isMobile, setIsMobile] = useState(false);
  const [limit, setLimit] = useState(4)
  const { order} = useContext(
    HubbleOrderContext
  )

  useEffect(() => {
    if (windowWidth > 768) {
      setIsMobile(false)
      setLimit(4)
    } else {
      setIsMobile(true)
      setLimit(2)
    }
  }, [windowWidth])


  return (
    <div className="product-list-category">
      <p className="title">{title}</p>
      {/* make this a component later */}
      <div className="product-list">
        {products.slice(0,limit).map((product) => {
          return <ContactsCartProductItem product={product}/>
        })}
      </div>
      {products.length > (isMobile ? 2 : 4) && (limit === (isMobile ? 2 : 4) ?
        <a className="see" href="#" onClick={(e) => {e.preventDefault() ; setLimit(20)}}> See More</a>
        :
        <a className="see" href="#" onClick={(e) => {e.preventDefault() ; setLimit(isMobile ? 2 : 4)}}> See Less</a>
      )}
    </div>
  )
}

export default ContactsCartProductList
