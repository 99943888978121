import React, {useState, useRef, useContext, useEffect} from 'react';

import './styles.scss';
import Prescription from '../Prescription';
import Brand from '../Brand';
import Checkout from '../Checkout';
import CheckoutV3 from '../CheckoutV3';
import Doctor from '../Doctor';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import DoctorV3 from '../DoctorV3';
import HubbleOrderContext from '../../../context/hubbleOrderContext';
import PreventBottomScroll from "../../PreventBottomScroll";


const OrderSection = ({isHydroSkyhy, isAffiliatePage, isIronclad, isV3, currentBrand}) => {
  const stripePromise = loadStripe(process.env.GATSBY_STRIPE_KEY);
  const { brandSelected, brand} = useContext(
    HubbleOrderContext
  )

  useEffect(() => {
    if (brand?.isAccessory)
      steps[0].ref.current = document.querySelector("#accessories-section");
    else
      steps[0].ref.current = document.querySelector("#lenses-section");
  }, [brand]);


  const steps = [
    { title: 'Brand', id: 1, ref: useRef(null) },
    { title: 'Rx', id: 2, ref: useRef(null) },
    { title: 'Doctor', id: 3, ref: useRef(null) },
    { title: 'Checkout', id: 4, ref: useRef(null) },
  ];
  const [activeSteps, setActiveSteps] = useState({
    Brand: true,
    Rx: false,
    Doctor: false,
    Checkout: false,
  });

  const handleActiveSteps = (obj) =>
    setActiveSteps((prevState) => ({ ...prevState, ...obj }));

  return (
    <section
      className={`${isHydroSkyhy ? "order-section-hydro-skyhy" : "order-section-intake"} ${isV3 ? "order-section-intake-v3" : ''} avg`}
      id="order-section"
    >
      <div className={"content-container"}>
        <h2 className={isV3 ? 'section-title' : ''}>Order Your Contacts</h2>
        <div className="steps-container">
          {activeSteps.Brand && (
            <Brand
              steps={steps}
              setActiveSteps={handleActiveSteps}
              isHydroSkyhy={isHydroSkyhy}
              isAffiliatePage={isAffiliatePage}
              currentBrand={currentBrand}
              isV3={isV3}
              isAVG={true}
            />
          )}
          {activeSteps.Rx && (
            <Prescription steps={steps} setActiveSteps={handleActiveSteps} isV3={isV3} isAvg/>
          )}
          {activeSteps.Doctor && (
            isV3 ?
            <DoctorV3 steps={steps} setActiveSteps={handleActiveSteps} isV3={isV3} isAvg/> :
            <Doctor steps={steps} setActiveSteps={handleActiveSteps} isV3={isV3}/>
          )}
          {activeSteps.Checkout && (
            <Elements stripe={stripePromise}>
              {
                isV3 ?
                <CheckoutV3 steps={steps} isHydroSkyhy={isHydroSkyhy} isAffiliatePage={isAffiliatePage} isIronclad={isIronclad} isAvg /> :
                <Checkout steps={steps} isHydroSkyhy={isHydroSkyhy} isAffiliatePage={isAffiliatePage} isIronclad={isIronclad} />
              }
            </Elements>
          )}
        </div>
        {
          activeSteps.Checkout && 
          <PreventBottomScroll background='#E8EAFF' />
        }
      </div>
    </section>
  )
};

export default OrderSection;
